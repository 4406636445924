<template>
  <div class="container">
    <Navbar />
    <ChatWindow />
    <NewChatForm />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import NewChatForm from '../components/NewChatForm.vue'
// import { projectAuth } from '../firebase/config'
import { useRouter } from 'vue-router'
import getUser from '../composables/getUser'
import { watch } from '@vue/runtime-core'
import ChatWindow from '../components/ChatWindow.vue'

export default {
  components: { Navbar, NewChatForm, ChatWindow },
  setup() {
    const router = useRouter()
    const { user } = getUser()

    watch( user, () => {
      if (!user.value)
        router.push({ name: 'Welcome'})
    })

    // projectAuth.onAuthStateChanged(() => {
    //   if (!user.value)
    //     router.push({ name: 'Welcome'})
    // })

  }
}
</script>

<style>

</style>