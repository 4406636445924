import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const useCollection = (collection) => {
    let error = ref(null)

    const addDoc = async (doc) => {
        error.value = null

        try {
            await projectFirestore.collection(collection).add(doc)
        } catch(err) {
            console.log(err.message)
            error.value = 'could not send message'
        }
    }

    return { addDoc, error }

}

export default useCollection